import '../../../styles/governance.css';
import React, { Component,useState,useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.css";
import {TransactionButton} from '../../../functions/Trx'; 

import { useAppContext } from '../../../context/AppContext'; // Import the useAppContext hook

const Governance = () => {
    // Access context values
    const {
        outputNumber,
        address,
        DAO_nm,
        DAO_ABI,
        DAO_Address,
        GovernanceTokenStakeBalance,
        Upgrader_nm,
        Upgrader_ABI,
        Upgrader_Address,
        updateExpiryVotes,
        updateFreezeVotes,
        VoteMachine_ABI,
        VoteMachine_Address,
        timeStampToDateAndTime,
        
    } = useAppContext();
    

    // State variables
    const [view, setView] = useState('grantVotes');
    const [styleCSS1, setStyleCSS1] = useState('governanceBoxClicked');
    const [styleCSS2, setStyleCSS2] = useState('governanceBox');
    const [styleCSS3, setStyleCSS3] = useState('governanceBox');
    const [styleCSS4, setStyleCSS4] = useState('governanceBox');
    const [freezeModalOpen, setFreezeModalOpen] = useState(false);
    const [assetToFreeze, setAssetToFreeze] = useState('');
    const [assetToVote, setAssetToVote] = useState('');
    const [voteModalOpen, setVoteModalOpen] = useState(false);
    const [assetToClose, setAssetToClose] = useState('');
    const [closeModalOpen, setCloseModalOpen] = useState(false);
    const [finalPriceModalOpen, setFinalPriceModalOpen] = useState(false);
    const [assetToVoteOnFinalPrice, setAssetToVoteOnFinalPrice] = useState('');
    const [initiateExpiryVoteModalOpen, setInitiateExpiryVoteModalOpen] = useState(false);
    const [assetToInitiateExpiryVote, setAssetToInitiateExpiryVote] = useState('');
    const [closeExpiryVoteModalOpen, setCloseExpiryVoteModalOpen] = useState(false);
    const [initiateGrantProposal, setInitiateGrantProposal] = useState(false);
    const [assetToCloseExpiryVote, setAssetToCloseExpiryVote] = useState('');
    const [voteOnGrantProposalModal, setVoteOnGrantProposalModal] = useState(false);
    const [selectedGrantVote, setSelectedGrantVote] = useState('');
    const [requestGrantButtonVisible, setRequestGrantButtonVisible] = useState(true);
    const [proposeNewAssetButtonVisible, setProposeNewAssetButtonVisible] = useState(false);
    const [initiateNewAssetProposal, setInitiateNewAssetProposal] = useState(false);
    const [proposeUgradeButtonVisible, setProposeUgradeButtonVisible] = useState(true);
    const [newAssetVotesDetailsOpen, setNewAssetVotesDetailsOpen] = useState([]);
    const [newAssetVotesDetailsClosed, setNewAssetVotesDetailsClosed] = useState([]);
    const [grantVotesDetailsOpen, setGrantVotesDetailsOpen] = useState([]);
    const [grantVotesDetailsClosed, setGrantVotesDetailsClosed] = useState([]);
    const [upgradeVotesDetailsOpen, setUpgradeVotesDetailsOpen] = useState();
    const [upgradeVotesDetailsClosed, setUpgradeVotesDetailsClosed] = useState();


    const [initiateUpgradeProposal, setInitiateUpgradeProposal] = useState();
    const [newSmartContractAddress, setNewSmartContractAddress] = useState();
    const [smartContractToUpgrade, setSmartContractToUpgrade] = useState();
    const [grantAmount, setGrantAmount] = useState();
    const [description, setDescription] = useState();
    const [proposedSymbol, setProposedSymbol] = useState();
    const [proposedName, setProposedName] = useState();
    const [proposedUpperLimit, setProposedUpperLimit] = useState();
    const [proposedDescription, setProposedDescription] = useState();
    const [selectedGrantVoteAmount, setSelectedGrantVoteAmount] = useState();
    const [selectedGrantVoteDescription, setSelectedGrantVoteDescription] = useState();
    const [voteOnUpgradeProposalModal, setVoteOnUpgradeProposalModal] = useState();
    const [selectedContractToUpgrade, setSelectedContractToUpgrade] = useState();
    const [selectedNewContractAddress, setSelectedNewContractAddress] = useState();
    const [selectedUpgradeVote, setSelectedUpgradeVote] = useState();
    const [voteOnNewAssetProposalModal, setVoteOnNewAssetProposalModal] = useState();
    const [selectedNewAssetVote, setSelectedNewAssetVote] = useState();
    const [selectedNewAssetName, setSelectedNewAssetName] = useState();
    const [expiryPriceToSubmit, setExpiryPriceToSubmit] = useState();
    const [selectedNewAssetDescription, setSelectedNewAssetDescription] = useState();
    
       

    useEffect(() => {
        if (DAO_nm !== undefined){loadNewAssetProposals();}
        if (DAO_nm !== undefined){loadGrantRequests();}
        if (DAO_nm !== undefined){loadUpgradeVotes();}
        console.log(DAO_nm)
    }, [DAO_nm]);
   

    const loadNewAssetProposals = async() =>{

        let numberOfNewAssetProposals = await DAO_nm.methods.numberOfNewAssetVotes().call();
        let newAssetsProposed = []
        console.log(numberOfNewAssetProposals)
        var _newAssetVotesDetailsOpen = [];
        console.log("Dbug")        
        var _newAssetVotesDetailsClosed = [];
        for (let i = 0; i < numberOfNewAssetProposals; ++i) {
            let proposedSymbol = await DAO_nm.methods.newAssetVoteSymbols(i).call()
            console.log(proposedSymbol)
            if (newAssetsProposed.includes(proposedSymbol)){
                continue
            }
            else {
                newAssetsProposed.push(proposedSymbol)
            }
            let result = await DAO_nm.methods.getNewAssetVotes(proposedSymbol).call()
            console.log(result)
            let hasVoted = await DAO_nm.methods.checkIfVotedNewAsset(address,proposedSymbol).call()
            console.log(hasVoted)
            if (result['open']){
                _newAssetVotesDetailsOpen.push([proposedSymbol,result['name'],result['description'],result['upperLimit'],result['endingTime'],result['yesVotes'],result['noVotes'],hasVoted])
            }
            else {
                _newAssetVotesDetailsClosed.push([proposedSymbol,result['name'],result['description'],result['upperLimit'],result['endingTime'],result['yesVotes'],result['noVotes'],hasVoted])
            }
            
            
        }
        if (parseFloat(GovernanceTokenStakeBalance) > 100000) {
            setProposeNewAssetButtonVisible(true);
            console.log("Button Visible")} 
        else {console.log(GovernanceTokenStakeBalance)};
            
        setNewAssetVotesDetailsOpen(_newAssetVotesDetailsOpen) 
        setNewAssetVotesDetailsClosed(_newAssetVotesDetailsClosed) 

    } 

    const loadGrantRequests = async() =>{
        let result = await DAO_nm.methods.getGrantVoteDetails(address).call()
        console.log(result);
        if (result[5] === true){
            setRequestGrantButtonVisible(false)
        }
        else{console.log("Button visible")};
        console.log(result)
        let numberOfGrantVotes = await DAO_nm.methods.numberOfGrantVotes().call();
        console.log(numberOfGrantVotes)
        

        var _grantVotesDetailsOpen = [];
        var _grantVotesDetailsClosed = [];
        for (let i = 0; i < numberOfGrantVotes; ++i) {
            console.log(i)
            let receivingAddress = await DAO_nm.methods.grantVoteAddresses(i).call()
            console.log(receivingAddress)
            
            let result = await DAO_nm.methods.getGrantVoteDetails(receivingAddress).call()
            console.log(result)
            let hasVoted = await DAO_nm.methods.checkIfVotedGrantFunding(address,receivingAddress).call()
            console.log(hasVoted)
            console.log(result);
            if (result[5]){
                _grantVotesDetailsOpen.push([receivingAddress,result[0],result[1],result[2],result[3],result[4],result[5],hasVoted])

            }
            else {
                _grantVotesDetailsClosed.push([receivingAddress,result[0],result[1],result[2],result[3],result[4],result[5],hasVoted])
            }
        }
        if (GovernanceTokenStakeBalance < 100000) {
            setRequestGrantButtonVisible(false)
        }
        else {setRequestGrantButtonVisible(true)};

        setGrantVotesDetailsOpen(_grantVotesDetailsOpen)
        setGrantVotesDetailsClosed(_grantVotesDetailsClosed)

    }

    const loadUpgradeVotes = async() =>{
        
        let result = await Upgrader_nm.methods.getUpgradeVoteDetails(address).call()
        console.log(result);
        if (result[5] === true){
            setProposeUgradeButtonVisible(false)
        }
        else{console.log("Button visible")};
        console.log(result)
        let numberOfUpgradeVotes = await Upgrader_nm.methods.numberOfUpgradeVotes().call();
        console.log(numberOfUpgradeVotes)
        

        var _upgradeVotesDetailsOpen = [];
        var _upgradeVotesDetailsClosed = [];
        for (let i = 0; i < numberOfUpgradeVotes; ++i) {
            console.log(i)
            let newContractAddress = await Upgrader_nm.methods.upgradeVoteAddresses(i).call()
            console.log(newContractAddress)
            
            let result = await Upgrader_nm.methods.getUpgradeVoteDetails(newContractAddress).call()
            console.log(result)
            let hasVoted = await Upgrader_nm.methods.checkIfVotedUpgrade(address,newContractAddress).call()
            console.log(hasVoted)
            console.log(result);
            if (result[4]){
                _upgradeVotesDetailsOpen.push([newContractAddress,result[0],result[1],result[2],result[3],result[4],result[5],hasVoted])

            }
            else {
                _upgradeVotesDetailsClosed.push([newContractAddress,result[0],result[1],result[2],result[3],result[4],result[5],hasVoted])
            }
        }
        if (GovernanceTokenStakeBalance < 100000){
                setRequestGrantButtonVisible(false)
        }
        else {
            setRequestGrantButtonVisible(true)
        }
        setUpgradeVotesDetailsOpen()
        setUpgradeVotesDetailsClosed()
        console.log(upgradeVotesDetailsOpen) 
        console.log(upgradeVotesDetailsClosed)
        console.log(requestGrantButtonVisible)      

    }     



    
    const openGrantPropsal = () => setInitiateGrantProposal(true);
    const closeGrantPropsal = () => setInitiateGrantProposal(false);

    const openUpgradePropsal = () => setInitiateUpgradeProposal(true);
    const closeUpgradePropsal = () => setInitiateUpgradeProposal(false);


    const checkNewGrantInput = async()=>{
        let _grantAmount = parseInt(document.getElementById('grantAmount').value)*(10**18)
        let _description = document.getElementById('grantDescription').value
        setGrantAmount(_grantAmount)
        setDescription(_description)
        loadGrantRequests();
    }

    const checkNewUpgradeInput = async()=>{
        let _smartContractToUpgrade = document.getElementById('smartContractToUpgrade').value
        let _newSmartContractAddress = document.getElementById('newSmartContractAddress').value
        setSmartContractToUpgrade(_smartContractToUpgrade)
        setNewSmartContractAddress(_newSmartContractAddress)
    }

    const onSuccessNewUpgradeProposal = async()=>{
        setInitiateUpgradeProposal(false)
        loadUpgradeVotes();        
    }


    const onSuccessVoteGrantProposal = async() => {
        setVoteOnGrantProposalModal(false)
        loadGrantRequests();
    }

    const onSuccessCloseGrantVote = async() =>{
        setVoteOnGrantProposalModal(false)
        loadGrantRequests();
    }

    const onSuccessVoteUpgradeProposal = async() => {
        setVoteOnUpgradeProposalModal(false)
        loadUpgradeVotes();
    }
    
    const onSuccessCloseUpgradeVote = async(newContractAddress) =>{
        loadUpgradeVotes();
    }
    
    const changeView = async (newView) =>{
       setView(newView)
        
        if (newView === "grantVotes"){
            setStyleCSS3("governanceBoxClicked")
            setStyleCSS4("governanceBox")
        }
        if (newView === "upgrade"){
            setStyleCSS3("governanceBox")
            setStyleCSS4("governanceBoxClicked")
        }
    }




    const closeVoteOnGrantProposalModal= () => {
        setVoteOnGrantProposalModal(false)
    };
    const openVoteOnGrantProposalModal= async(receiver) => {
        setVoteOnGrantProposalModal(true)
        setSelectedGrantVote(receiver)
        let result = await DAO_nm.methods.getGrantVoteDetails(receiver).call()
        console.log(result)
        setSelectedGrantVoteAmount(result[3])
        setSelectedGrantVoteDescription(result[4])
        
    }

    const onSuccessNewGrantProposal = async()=>{
        setInitiateGrantProposal(false)
        loadGrantRequests()
    }


    const openVoteOnUpgradeProposalModal= async(newContractAddress) => {
        setVoteOnUpgradeProposalModal(true)
        setSelectedUpgradeVote(newContractAddress)
        let result = await Upgrader_nm.methods.getUpgradeVoteDetails(newContractAddress).call()

        setSelectedNewContractAddress(newContractAddress)
        setSelectedContractToUpgrade(result[3])
    }

    const closeVoteOnUpgradeProposalModal= () => {
        setVoteOnUpgradeProposalModal(false)
    };



    const tooltip3 = props => (
        <Tooltip {...props}>
        TWIN owners may propose to receive TWIN from the DAO grant reserve as reward for exeptional value-add they contributed to the TWIN
        DAO.
        </Tooltip>
    );
    const tooltip4 = props => (
        <Tooltip {...props}>
        TWIN holders can suggest and vote on upgrades of the existing smart contracts.
        </Tooltip>
    );
    const tooltip8 = props => (
        <Tooltip {...props}>
            Max 100,000 TWIN
        </Tooltip>
    );


       
        


        let showDAOvotes
        if (typeof(grantVotesDetailsOpen) != 'undefined'){
            showDAOvotes = grantVotesDetailsOpen.map((details,index) =>
                <div key ={index} className="governanceAssetBox">
                    <div key ={index} className="h-100 p-1 mb-4 col">
                        <div id="" key ={index} className="h-100 p-4 col">

                            <div className="row">
                                <div className="col-12 mb-2"><b>Active TWIN DAO grant proposal #{index+1}</b></div>
                            </div>
                            <div className="row mb-3">
                                <div className="col my-auto"><b>Receiver:</b></div>
                                <div className="col addressGovernance my-auto mb-2">{details[0]}</div>
                            </div>
                            
                            <div className="row">
                                <div className="col"><b>Vote ends:</b></div>
                                <div className="col">{timeStampToDateAndTime(parseInt(details[1]))}</div>
                            </div>
                            <div className="row">
                                <div className="col"><b>Requested Amount:</b></div>
                                <div className="col">{outputNumber(parseInt(details[4])/(10**18),0)} TWIN</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col"><b>Reason for the requested DAO grant:</b></div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">{details[5]}</div>
                            </div>
                            <div className="row">
                                <div className="col">YES votes</div>
                                <div className="col">
                                    {parseInt(details[2])>0 ?
                                        <span>(parseInt(details[2])*100/(parseInt(details[3])+parseInt(details[2]))).toFixed(1)</span>
                                        :
                                        <span>0</span>
                                    }
                                    %
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">NO votes</div>
                                <div className="col">{parseInt(details[3]) > 0 ?
                                    <span>(parseInt(details[3])*100/(parseInt(details[3])+parseInt(details[2]))).toFixed(1)</span>
                                    :
                                    <span>0</span>
                                }
                                %
                            </div>
                            </div>

                            {parseInt(details[1])*1000 > Date.now() && details[7] === false 
                                ?
                                <Button className='issuaaButton' onClick={()=>openVoteOnGrantProposalModal(details[0])}>
                                    Vote
                                </Button>
                                :
                                ''
                            }
                            {parseInt(details[1])*1000 <= Date.now() 
                                ?
                                <TransactionButton
                                    abi={DAO_ABI}
                                    contractAddress={DAO_Address}
                                    functionName="closeGrantFundingVote"
                                    args={[details[0]]}
                                    text="Close Vote"
                                    onSuccess = {onSuccessCloseGrantVote}
                                /> 
                                :
                                ''
                            }
                                
                        </div>
                    </div>
                </div>
            )
        }
        let showUpgradevotes
        if (typeof(upgradeVotesDetailsOpen) != 'undefined'){
            showUpgradevotes = upgradeVotesDetailsOpen.map((details,index) =>
            <div key ={index} className="governanceAssetBox">
                    <div key ={index} className="h-100 p-1 mb-4 col">
                        <div id="innerBox" key ={index} className="h-100 p-4 col">

                            <div className="row">
                                <div className="col-12 mb-2"><b>TWIN Code Upgrade proposal #{index+1}</b></div>
                            </div>
                            <div className="row mt-3">
                                <div className="col"><b>Contract to be upgraded:</b></div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">{details[4]}</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col my-auto"><b>New implementation contract address:</b></div>
                                <div className="col addressGovernance my-auto mb-2">{details[0]}</div>
                            </div>
                            
                            <div className="row">
                                <div className="col"><b>Vote ends:</b></div>
                                <div className="col">{timeStampToDateAndTime(parseInt(details[1]))}</div>
                            </div>
                            
                            
                            <div className="row">
                                <div className="col">YES votes</div>
                                <div className="col">{parseInt(details[2]>0 ? 
                                    <span>(parseInt(details[2])*100/(parseInt(details[3])+parseInt(details[2]))).toFixed(1)</span>
                                    :
                                    <span>0</span>
                                    )}
                                    %
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">NO votes</div>
                                <div className="col">{parseInt(details[3])>0 ?
                                    <span>(parseInt(details[3])*100/(parseInt(details[3])+parseInt(details[2]))).toFixed(1)</span>
                                    :
                                    <span>0</span>
                                    }
                                    %
                                </div>
                            </div>

                            {parseInt(details[1])*1000 > Date.now() && details[7] === false 
                                ?
                                <Button className='issuaaButton' onClick={()=>openVoteOnUpgradeProposalModal(details[0])}>
                                    Vote
                                </Button>
                                :
                                null
                            }
                            {parseInt(details[1])*1000 <= Date.now() 
                                ?
                                <TransactionButton
                                    abi={Upgrader_ABI}
                                    contractAddress={Upgrader_Address}
                                    functionName="closeUpgradeVote"
                                    args={[details[0]]}
                                    text="Close Vote"
                                    onSuccess = {onSuccessCloseUpgradeVote}
                                />
                                
        
                                :
                                null
                            }
                                
                        </div>
                    </div>
                </div>
            )
        }


        return ( 
            
            <div className="mainContainer">
                
                <Modal show={initiateUpgradeProposal} onHide={closeUpgradePropsal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Propose an Upgrade to the existing smart contracts of TWIN:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">
                            
                        <div className="row vertical_center">
                            <div className="col-12 vertical_center">
                                <b>Which smart contract would you like to upgrade?&nbsp;</b>
                            </div>
                            
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <input className="form-control" type='text' id='smartContractToUpgrade' onChange={() =>checkNewUpgradeInput()}></input>
                                
                            </div>
                        </div>

                        <div className="row vertical_center">
                            <div className="col-12 vertical_center">
                                <b>Please insert the address of the new smart contract:&nbsp;</b>
                            </div>                                
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <input className="form-control" type='text' id='newSmartContractAddress' onChange={() =>checkNewUpgradeInput()}></input>
                            </div>
                        </div>


                        
                            
                            
                    </Modal.Body>
                    <Modal.Footer>
                        <TransactionButton
                            abi={Upgrader_ABI}
                            contractAddress={Upgrader_Address}
                            functionName="initiateUpgradeVote"
                            args={[newSmartContractAddress,smartContractToUpgrade]}
                            text="Submit Proposal"
                            onSuccess = {onSuccessNewUpgradeProposal}
                        />  
        
                        
                    </Modal.Footer>
                </Modal>

                <Modal show={initiateGrantProposal} onHide={closeGrantPropsal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Initiate a new DAO grant proposal:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">
                            
                        <div className="row vertical_center">
                            <div className="col-12 vertical_center">
                                <b>How many TWIN are you requesting?&nbsp;</b>
                                <OverlayTrigger placement="right" overlay={tooltip8}>
                                    <InfoOutlinedIcon/>
                                </OverlayTrigger>
                            </div>
                            
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <input className="form-control" type='number' id='grantAmount' onChange={() =>checkNewGrantInput()}></input>
                                
                            </div>
                        </div>

                        <div className="row vertical_center">
                            <div className="col-12 vertical_center">
                                <b>Please describe what you will do for the proposed grant, which helps the TWIN protocol to foster:&nbsp;</b>
                            </div>                                
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <textarea className="w-100" id='grantDescription' rows="7" onChange={() =>checkNewGrantInput()}></textarea>
                            </div>
                        </div>


                        
                            
                            
                    </Modal.Body>
                    <Modal.Footer>
                        <TransactionButton
                            abi={DAO_ABI}
                            contractAddress={DAO_Address}
                            functionName="initiateGrantFundingVote"
                            args={[address,grantAmount,description]}
                            text="Submit Proposal"
                            onSuccess = {onSuccessNewGrantProposal}
                        />  
                        
                        
                    </Modal.Footer>
                </Modal>

                

                <Modal show={voteOnGrantProposalModal} onHide={closeVoteOnGrantProposalModal}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                            <div className="row p-2 h3">Vote on the following Grant Request:</div>
                            <div className="row px-2 mb-2">
                                <div className='w-100'>
                                    <b>Receiver:</b>
                                </div>
                                <div>
                                    {selectedGrantVote}
                                </div>
                            </div>
                            <div className="row px-2 mb-2">
                                <div className='w-100'>
                                    <b>Amount:</b>
                                </div>
                                <div>
                                    {(parseInt(selectedGrantVoteAmount)/(10**18))} TWIN
                                </div>
                            </div>
                            <div className="row px-2">
                                <div className='w-100'>
                                    <b>Description:</b>
                                </div>
                                <div> 
                                    {selectedGrantVoteDescription}
                                </div>
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <TransactionButton
                        abi={DAO_ABI}
                        contractAddress={DAO_Address}
                        functionName="voteGrantFundingVote"
                        args={[selectedGrantVote,true]}
                        text="Yes"
                        onSuccess = {onSuccessVoteGrantProposal}
                    /> 
         
                    <TransactionButton
                        abi={DAO_ABI}
                        contractAddress={DAO_Address}
                        functionName="voteGrantFundingVote"
                        args={[selectedGrantVote,false]}
                        text="No"
                        onSuccess = {onSuccessVoteGrantProposal}
                    />
                    
                        
                    </Modal.Footer>
                </Modal>

                <Modal show={voteOnUpgradeProposalModal} onHide={closeVoteOnUpgradeProposalModal}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                            <div className="row p-2">Vote on the following Upgrade Proposal:</div>
                            <div className="row px-2">Contract to be replaced: {selectedContractToUpgrade}</div>
                            <div className="row px-2">New Implmentation Address: {selectedNewContractAddress}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <TransactionButton
                            abi={Upgrader_ABI}
                            contractAddress={Upgrader_Address}
                            functionName="voteUpgradeVote"
                            args={[selectedUpgradeVote,true]}
                            text="Yes"
                            onSuccess = {onSuccessVoteUpgradeProposal}
                        /> 
         
                        <TransactionButton
                            abi={Upgrader_ABI}
                            contractAddress={Upgrader_Address}
                            functionName="voteUpgradeVote"
                            args={[selectedUpgradeVote,false]}
                            text="No"
                            onSuccess = {onSuccessVoteUpgradeProposal}
                        />
                    </Modal.Footer>
                </Modal>

                
                <div className="middleBoxFull">
                    <div className="">
                        
                            <div className="mainBox">
                                <div className="governanceIntroText">
                                    As a decentralized autonomous organization (DAO) the TWIN protocol is governed exclusively via voting procedures 
                                    by the community of owners of the TWIN Protocol Token (TWIN). 
                                    One staked TWIN represents one vote in TWIN governance votings.
                                </div>
                                <div className="infoRow">
                                    

                                    <div className="governanceBox governanceBox40" onClick={()=>changeView('grantVotes')} role="button">
                                        TWIN DAO grant proposals & votings&nbsp;
                                        <OverlayTrigger placement="top" overlay={tooltip3}>
                                            <InfoOutlinedIcon/>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="governanceBox governanceBox80" onClick={()=>changeView('upgrade')} role="button">
                                        Smart Contract Code Upgrade Votes&nbsp;
                                        <OverlayTrigger placement="top" overlay={tooltip4}>
                                            <InfoOutlinedIcon/>
                                        </OverlayTrigger>
                                    </div>
                                    
                                    

                                </div>

                            </div>
                            <div className="governanceOutput">

                                
                                {view === "grantVotes" ?
                                    <div className="w-100">
                                        <div className="row m-0">
                                            {requestGrantButtonVisible ?
                                                <div role="button" className="governanceButtonLightGreen w-100" onClick={()=>openGrantPropsal()}>Are you planning on doing something that helps the TWIN protocol? Request a DAO grant!</div>
                                                :
                                                ''
                                            }

                                        </div>
                                        <div className="pl-0 ml-0 row p-0 mr-0 pr-0" id="3">
                                            <div className="row ml-0 mr-0 w-100 my-auto py-4">
                                                
                                                {showDAOvotes}
                                            </div>
                                        </div>
                                       
                                    </div>

                                :
                                    null
                                }

                                {view === "upgrade" ?

                                    <div className="w-100">
                                    
                                        <div className="row m-0">

                                            {GovernanceTokenStakeBalance > 100000 ?
                                                <div role="button" className="governanceButtonLightGreen w-100"onClick={()=>openUpgradePropsal()}>Propose an upgrade to the existing smart contracts!</div>
                                                    
                                                :
                                                null
                                            }

                                        </div>
                                        <div className="pl-0 ml-0 row p-0 mr-0 pr-0" id="3">
                                            <div className="row ml-0 mr-0 w-100 my-auto py-4">
                                                {showUpgradevotes}
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                                }
                            </div>
                    </div>
                </div>    
            </div>
         );
    
}

export default Governance;